import { Paginator } from "primereact/paginator"
import { useState } from "react"
import WorkflowListRow from "./WorkflowListRow"
import styles from "./WorkflowList.module.scss"

interface Props {
  workflowIds: string[]
}

function WorkflowList({ workflowIds }: Props) {
  const [paginationFirstIndex, setPaginationFirstIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(parseInt(localStorage.getItem("workflowlist.pageSize") || "10"))

  const sortedList = workflowIds.sort((a: string, b: string) => {
    return a > b ? -1 : a < b ? 1 : 0
  })

  const slicedList = sortedList.slice(paginationFirstIndex, paginationFirstIndex + pageSize)

  const onPageChange = (event: any) => {
    setPaginationFirstIndex(event.first)
    setPageSize(event.rows)
    localStorage.setItem("workflowlist.pageSize", event.rows)
  }

  return (
    <div className={styles.list}>
      <p>Total workflows: {workflowIds.length}</p>
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Id</th>
            <th>Name</th>
            <th>Version</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {slicedList.map((id) => (
            <WorkflowListRow key={id} workflowId={id} />
          ))}
        </tbody>
      </table>
      <Paginator
        first={paginationFirstIndex}
        rows={pageSize}
        totalRecords={workflowIds.length}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </div>
  )
}
export default WorkflowList
